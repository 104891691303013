import { useState } from "react";
import { createContext } from "react";

import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';


export const CategoriesContext = createContext()

export const CategoriesProvider = ({ children }) => {

    const [lang, setLang] = useState(() => {
        return localStorage.getItem("lang") || 'ar';
    });
 const { i18n } = useTranslation();
 useEffect(()=>{
     const langLocal =localStorage.getItem("lang");
    if(langLocal){
        setLang(langLocal)
    }else{
        localStorage.setItem("lang", lang);
    }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[])
 useEffect(() => {
    localStorage.setItem("lang", lang);
}, [lang]);


 const changeLanguage = (newLang) => {
     i18n.changeLanguage(newLang);
 };

 const [categories,setCategories] = useState()
 const [news,setNews] = useState()
 const [categories24,setCategories24] = useState()


 const getCategories = async()=>{
    try {
        const response = await axios.get("https://t3zez.poultrystore.net/api/categories", {headers:{apiSecret:"MDb#jfr7823$jd",lang}})
        setCategories(response.data.data)
    } catch (error) {
        toast.error(error.message);
    }
}
 const getCategories24 = async()=>{
    try {
        const response = await axios.get("https://t3zez.poultrystore.net/api/program", {headers:{apiSecret:"MDb#jfr7823$jd",lang}})
        setCategories24(response.data.data)
    } catch (error) {
        toast.error(error.message);
    }
}
 const getNews = async()=>{
    try {
        const response = await axios.get("https://t3zez.poultrystore.net/api/news", {headers:{apiSecret:"MDb#jfr7823$jd",lang}})
        setNews(response.data.data)
    } catch (error) {
        toast.error(error.message);
    }
}

useEffect(()=>{
    getCategories();
    getNews();
    getCategories24();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[lang])
 

const [activeProgram,setActiveProgram] = useState('all')
  const [programs,setPrograms] = useState();
  const [loadingPrograms,setLoadingPrograms] = useState(true);


  const [word,setWord] = useState();

  return <CategoriesContext.Provider value={{categories,news,setNews,word,setWord,lang,setLang,changeLanguage,activeProgram,setActiveProgram,programs,setPrograms,loadingPrograms,setLoadingPrograms,categories24}}>
    {children}
  </CategoriesContext.Provider>
}