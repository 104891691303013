import React, { useEffect } from 'react'
import './ImagesHome.css'
import MainLayout from 'components/mainLayout/MainLayout'
import CommonSection from 'components/common_section/CommonSection'
import SearchCommon from 'ui/searchCommon/SearchCommon'
import { Container } from 'react-bootstrap'
import ImageList from 'components/ImagesPageComponents/ImageCard/ImageList'
import { useTranslation } from 'react-i18next'
import transition from 'transition'
import { Helmet } from 'react-helmet-async'
const ImagesHome = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const { t } = useTranslation(); 

  return (
    <>
    <Helmet>
      <title>S.E.T.C || GALLERY</title>
            <meta
              name="description"
                content='تعرف علي اخر الاخبار و الفاعليات و الاعلانات لمركز تعزيز المهارات للتدريب وشاهد احدث الصور والفيديوهات الخاصه باخر الاخبار للمركز'
            />
            <link rel='canonical' href='/Images'/>
    </Helmet>
        <MainLayout>
            <CommonSection title={t("Gallery")}>
                <SearchCommon/>
            </CommonSection>
            <div className='ImagesPage'>
                <Container>
                    <div className='ImagesPage_container'>
                        <ImageList/>
                    </div>
                </Container>
            </div>
        </MainLayout>
    </>
  )
}

export default transition(ImagesHome)