import React, { useContext, useEffect, useRef, useState } from 'react'
import './SuggestProgram.css'
import { Button, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import MainHead from 'ui/MainHead/MainHead';
import { CategoriesContext } from 'contextApi/CategoriesContext';
import { t } from 'i18next';
import { motion,useScroll, useTransform } from "framer-motion";


import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'


const SuggestProgram = () => {
    const itemsRef = useRef(null);
    const [isMouseDown, setIsMouseDown] =  useState(false);
    const [startX, setStartX] =  useState(0);
    const [ScrollLeft, setScrollLeft] =  useState(0);

    const handleMouseDown =(e)=>{
        setIsMouseDown(true);
        setStartX(e.pageX - itemsRef.current.offsetLeft);
        setScrollLeft(itemsRef.current.ScrollLeft);
    }
    const handleMouseUp =()=>{
        setIsMouseDown(false);
    }
    const handleMouseMove = (e) => {
        e.preventDefault();
        if (!isMouseDown) return;
        const x = e.pageX - itemsRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        itemsRef.current.scrollLeft = ScrollLeft - walk;
      };
    
      
      
      
      // const categories = useSelector((state)=> state.categories.data.data);
      // const loadingCategories = useSelector((state)=>state.categories.loading); 
      
      const [activeProgram,setActiveProgram] = useState('all')
      const {categories} = useContext(CategoriesContext)
      const [programs,setPrograms] = useState();
      const [loadingPrograms,setLoadingPrograms] = useState(true);
      useEffect(()=>{
        const selectedPrograms = categories?.find((item)=> item.id === activeProgram);
        setPrograms(selectedPrograms)
        setLoadingPrograms(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[activeProgram])

            
      const ref = useRef(null);
      const {scrollYProgress}=useScroll({
        target: ref,
        offset: ['0 1','1.33 1']
      })
      const scaleProgress = useTransform(scrollYProgress,[0,1],[.8,1])
      // const opacityProgress = useTransform(scrollYProgress,[0,1],[.8,1])


      const handleScroll = (scrollOffset) => {
        if (itemsRef.current) {
          itemsRef.current.scrollLeft += scrollOffset;
        }
      };

      const { lang } = useContext(CategoriesContext);
      const [visibleCards, setVisibleCards] = useState(1);

      const showMoreCards = () => {
        setVisibleCards(prevVisibleCards => prevVisibleCards + 1);
      };
  return (
    <div className="SuggestProgram">
      <Container>
        <MainHead
          title={t('ProposedPrograms')}
          description={t('centerOffers')}
          link={'/programs'}
        />
        <div className={`scrollContainer`}>
          <Button variant='success' className="scrollArrow" onClick={() => handleScroll(-100)}>
            {/* {'>'} */}
            <i className={`${lang === 'ar' ? 'ri-arrow-right-s-line' : 'ri-arrow-left-s-line'}`}></i>
          </Button>
          <div
            className="hosScroll"
            ref={itemsRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseUp}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <NavLink
              className={
                activeProgram === 'all'
                  ? 'ScrollLink activeScrollLink'
                  : 'ScrollLink'
              }
              onClick={() => setActiveProgram('all')}
            >
              {t('allPrograms')}
            </NavLink>
            {
              // !loadingCategories ?
              categories?.map((item) => (
                <NavLink
                  key={item.id}
                  className={
                    activeProgram === item.id
                      ? 'ScrollLink activeScrollLink'
                      : 'ScrollLink'
                  }
                  onClick={() => setActiveProgram(item.id)}
                >
                  {item.name}
                </NavLink>
              ))
            }
          </div>
          <Button variant='success' className="scrollArrow" onClick={() => handleScroll(100)}>
            {/* {'>'} */}
            <i className={`${lang === 'ar' ? 'ri-arrow-left-s-line' :  'ri-arrow-right-s-line'}`}></i>
          </Button>
        </div>
        <div className="suggestCards" ref={ref}>
          {!loadingPrograms &&
            programs?.courses?.map((item) => (
              <motion.div
                className="suggestCard"
                key={item.id}
                style={{ scale: scaleProgress }}
              >
                <div className="cardSugImg">
                  <img src={item.image ? item.image : imgLogo} alt="programImg" />
                  <div className="hourProg">
                    <span>
                      {Math.round(item.duration)} {t('hour')}
                    </span>
                  </div>
                </div>
                <div className="cardSugContent">
                  <h4>{item.name}</h4>
                  <h6>{item.description}</h6>
                </div>
                <div className="cardsugFooter">
                  {/* <div className="cardsugprice">
                    <h3>{item.price_after_discount} {t("currency")}</h3>
                    <span>{item.price_before_discount} {t("currency")}</span>
                  </div> */}
                  <div className="cardSugBtn">
                    <a href="##">{t('RegisterNow')}</a>
                  </div>
                </div>
              </motion.div>
            ))}
          {activeProgram === 'all' &&
            //  loadingCategories === false &&
            categories?.map((item) => {
              return item?.courses.slice(0, visibleCards).map((course) => (
                <motion.div
                  className="suggestCard"
                  key={course.id}
                  style={{ scale: scaleProgress }}
                >
                  <div className="cardSugImg">
                    <img src={item.image ? item.image : imgLogo} alt="programImg" />
                    <div className="hourProg">
                      <span>
                        {course.duration}
                      </span>
                    </div>
                  </div>
                  <div className="cardSugContent">
                    <h4>{course.name}</h4>
                    <h6>{course.description}</h6>
                  </div>
                  <div className="cardsugFooter">
                    <div className="cardsugprice">
                      <h3>{course.price_after_discount} {t("currency")}</h3>
                      <span>{course.price_before_discount} {t("currency")}</span>
                    </div>
                    <div className="cardSugBtn">
                      <a href="##">{t('RegisterNow')}</a>
                    </div>
                  </div>
                </motion.div>
              ));
            })}

        </div>
        {categories?.flatMap(item => item.courses).length > visibleCards && (
          <Button variant='success' className="scrollArrow" style={{display:'block',margin:'15px auto'}}  onClick={showMoreCards}>
          {/* {'>'} */}
          {t('seeMore')}
        </Button>
      )}
      </Container>
    </div>
  );
}

export default SuggestProgram