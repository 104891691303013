import { AnimatePresence } from "framer-motion";
import i18n from "i18n";
import BeTrainer from "pages/BeTrainer/BeTrainer";
import ImagesHome from "pages/ImagesHome/ImagesHome";
import NewDeatails from "pages/NewDetails/NewDeatails";
import AboutUs from "pages/aboutUs/AboutUs";
import ContactUs from "pages/contactUs/ContactUs";
import Home from "pages/home/Home";
import News from "pages/news/News";
import Programs24 from "pages/programs24/Programs24";
import TrainingPrograms from "pages/trainingPrograms/TrainingPrograms";
import NotFound from 'pages/not-found/NotFound';
import { useEffect } from "react";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
// import { fetchCategories } from "rtk/slices/CategoriesSlice";


function App() {
useEffect(() => {
  const langLocal = localStorage.getItem("lang");
  if (langLocal && ['en', 'ar'].includes(langLocal)) {
    i18n.changeLanguage(langLocal); // Set the language from local storage
  } 
}, []);
  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/about" element={<AboutUs/>}/>
        <Route path="/programs" element={<TrainingPrograms/>}/>
        <Route path="/programs24" element={<Programs24/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/Images" element={<ImagesHome/>}/>
        <Route path="/NewDetails/:id" element={<NewDeatails/>}/>
        <Route path="/ContactUs" element={<ContactUs/>}/>
        <Route path="/BeTrainer" element={<BeTrainer/>}/>
        <Route path="/*" element={<NotFound/>}/>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
