import React from 'react'
import './Strategy.css'
import NumLi from 'ui/numLi/NumLi'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
const Strategy = () => {
    const { t } = useTranslation();

  return (
    <div className='Qema trategy'>
        <Container>
            <div className='qemaContainer'>
                <h2>{t("strategicgoals")}</h2>
                <ul>
                    <li>
                        <NumLi num='1'/>
                        <h4>
                            {t("Preparingtraining")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='2'/>
                        <h4>
                            {t("professionalismtraining")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='3'/>
                        <h4>
                            {t("Focusprinciple")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='4'/>
                        <h4>
                            {t("Providingtraining")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='5'/>
                        <h4>
                            {t("Strengtheningconcept")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='6'/>
                        <h4>
                            {t("Payingattention")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='7'/>
                        <h4>
                            {t("Providingtrainingprograms")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='8'/>
                        <h4>
                            {t("Attractingmale")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='9'/>
                        <h4>
                            {t("Promotingconcept")}
                        </h4>
                    </li>
                </ul>
            </div>
        </Container>
    </div>
  )
}

export default Strategy