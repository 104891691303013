import Footer from 'components/layouts/footer/Footer'
import Header from 'components/layouts/header/Header'
import React, { useContext } from 'react'
import './Main.css'
import { CategoriesContext } from 'contextApi/CategoriesContext'
const MainLayout = (props) => {
    const { lang } = useContext(CategoriesContext);

  return (
    <>
    <Header/>
        <main className={lang==="en" ? 'mainEn' : ''}>
            {props.children}
        </main>
    <Footer/>
    </>
)
}

export default MainLayout