import React, { useContext, useEffect } from 'react'
import './MainNew.css'
// import new2 from 'assets/images/news2.png'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useParams } from 'react-router-dom'
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const MainNew = () => {
    const {news} = useContext(CategoriesContext);
    const {id} = useParams();
    const newDetails = news?.find((item)=> +item.id === +id);

    useEffect(()=>{
        window.scrollTo(0,0)
    },[id])
  return (
    <div className='MainNew mb-3'>
        <div className='MainNew_img'>
            <img src={newDetails?.image ?? imgLogo} alt='Main_new'/>
        </div>
        <h2 className='MainNew_head'>
        {newDetails?.title}
        </h2>
        <div className='MainNew_content'>
            <div className='MainNew_date'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.66675 1.66663V4.16663" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.3333 1.66663V4.16663" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.91675 7.57495H17.0834" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.99632 11.4167H10.0038" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91185 11.4167H6.91933" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.91185 13.9167H6.91933" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{newDetails?.date}</span>
            </div>
            <p>{newDetails?.description}</p>
        </div>
    </div>
  )
}

export default MainNew