import React, { useContext, useEffect } from 'react'
import './ProgramsList.css'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useTranslation } from 'react-i18next'

const ProgramsList = () => {
  const {categories} = useContext(CategoriesContext)
  const {activeProgram,setActiveProgram} = useContext(CategoriesContext)
  const {setPrograms} = useContext(CategoriesContext)
  const {setLoadingPrograms} = useContext(CategoriesContext)
  useEffect(()=>{
    const selectedPrograms = categories?.find((item)=> item.id === activeProgram);
    setPrograms(selectedPrograms)
    setLoadingPrograms(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeProgram])
  const { t } = useTranslation(); 

  return (
    <div className='ProgramsList mb-3'>
      <ul>
      <li className={
                activeProgram === 'all'
                  ? 'activeLi'
                  : ''
              }
              onClick={() => setActiveProgram('all')}>{t("allPrograms")}</li>
            {
              categories?.map((item) => (
                <li 
                key={item.id}
                className={
                    activeProgram === item.id
                      ? 'activeLi'
                      : ''
                  }
                  onClick={() => setActiveProgram(item.id)}>{item.name}
                </li>
              ))
            }
        </ul>
    </div>
  )
}

export default ProgramsList