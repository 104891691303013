import React, { useEffect } from 'react'
import './NewDetails.css'
import MainLayout from 'components/mainLayout/MainLayout'
import CommonSection from 'components/common_section/CommonSection'
import { Col, Container, Row } from 'react-bootstrap'
import MainNew from 'components/NewDetailsComponents/MainNew/MainNew'
import MoreNews from 'components/NewDetailsComponents/MoreNews/MoreNews'
import { useTranslation } from 'react-i18next'
const NewDeatails = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const { t } = useTranslation(); 

  return (
    <MainLayout>
        <CommonSection title={t("Newsdetails")}/>
        <div className='newDetailsPage'>
            <Container>
                <Row>
                    <Col lg="7">
                        <MainNew/>
                    </Col>
                    <Col lg="5">
                        <MoreNews/>
                    </Col>
                </Row>
            </Container>
        </div>
    </MainLayout>
  )
}

export default NewDeatails