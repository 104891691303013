import React, { useState } from 'react'
import { toast } from 'react-toastify';
import './TrainerForm.css';
import { useTranslation } from 'react-i18next';
const TrainerForm = () => {
    const [name,setName] = useState();
    const [phone,setPhone] = useState();
    const [email,setEmail] = useState();
    const [dept,setDept] = useState();
    const [years,setYears] = useState();
    const [files,setFiles] = useState();
    const submitForm = async(e)=>{
        e.preventDefault();
        try{
            const formData = new FormData();
            formData.append('full_name', name);
            formData.append('email', email);
            formData.append('phone_number', phone);
            formData.append('specialization', dept);
            formData.append('experience_years', years);
            formData.append('images[]', files);
            const res = await fetch("https://t3zez.poultrystore.net/api/trainers/create", {
                method: "POST",
                headers: {
                    'apiSecret': "MDb#jfr7823$jd"
                },
                body: formData
            });
            const data = await res.json(); 
            if (data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }  catch (error) {
            toast.error("An error occurred while submitting the form");
        }
    }
    const { t } = useTranslation(); 

  return (
    <div className="ContactForm" onSubmit={submitForm}>
      <h3>{t("beTrainer")}</h3>
      <form>
        <input type='text' placeholder={t("nameHolder")} id='name' required onChange={(e)=>setName(e.target.value)}/>
        <input type='number' placeholder={t("phoneHolder")} id='name' required onChange={(e)=>setPhone(e.target.value)}/>
        <input type='email' placeholder={t("mailHolder")} id='mail' required onChange={(e)=>setEmail(e.target.value)}/>
        <input type='text' placeholder={t("specialize")} id='subject' required onChange={(e)=>setDept(e.target.value)}/>
        <input type='text' placeholder={t("yearsEx")} id='years' required onChange={(e)=>setYears(e.target.value)}/>
        <label htmlFor="fileInput" className="custom-file-upload">
            <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <g clipPath="url(#clip0_30_1296)">
                        <path d="M14.5018 2.59745C12.5077 0.60326 9.26289 0.60326 7.26845 2.59745L1.0682 8.79767C-0.356145 10.2222 -0.356051 12.5399 1.06839 13.9644C1.78067 14.6766 2.71598 15.0328 3.65167 15.0327C4.58711 15.0326 5.52292 14.6765 6.23507 13.9644L11.9184 8.28082C12.3325 7.86682 12.5606 7.31635 12.5606 6.73085C12.5606 6.14532 12.3327 5.59488 11.9185 5.18076C11.0638 4.3261 9.67304 4.32613 8.81848 5.18092L5.10176 8.89754C4.81642 9.18285 4.81642 9.64551 5.1017 9.93088C5.38701 10.2163 5.84967 10.2162 6.13504 9.93088L9.85179 6.21423C10.1367 5.92932 10.6002 5.92926 10.8851 6.21417C10.9532 6.28186 11.0072 6.36237 11.0439 6.45106C11.0806 6.53974 11.0994 6.63483 11.0992 6.73082C11.0992 6.92598 11.0232 7.10942 10.8851 7.24748L5.20173 12.931C4.34701 13.7856 2.95639 13.7857 2.10173 12.931C1.24707 12.0763 1.24701 10.6856 2.10157 9.83091L8.30179 3.63079C9.72636 2.20629 12.0441 2.20629 13.4685 3.63079C14.1586 4.32079 14.5386 5.23826 14.5386 6.2141C14.5386 7.18995 14.1585 8.10742 13.4685 8.79748L7.26836 14.9978C6.98304 15.2831 6.98304 15.7458 7.26842 16.0311C7.33619 16.0991 7.41672 16.153 7.50539 16.1897C7.59405 16.2264 7.6891 16.2453 7.78507 16.2451C7.88105 16.2452 7.9761 16.2264 8.06477 16.1897C8.15344 16.153 8.23398 16.0991 8.30176 16.0311L14.5018 9.83085C15.4679 8.86482 15.9999 7.58038 16 6.21413C16 4.84792 15.4679 3.56348 14.5018 2.59745Z" fill="#287761"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_30_1296">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.673462)"/>
                        </clipPath>
                    </defs>
                </svg>
            </span>
            <span>{t("Attachcertificates")}</span>
            <input id="fileInput" type="file" style={{ display: "none" }} multiple onChange={(e)=>setFiles(e.target.files)}/>
        </label>
        <span>{files && "Files added Successfully!"}</span>
        <button type='submit'>{t("send")}</button>
      </form>
    </div>
  )
}

export default TrainerForm