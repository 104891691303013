import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import ProgramCard from './ProgramCard'
// import Pagination from 'components/programComonents/pagination/Pagination'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import ReactPaginate from 'react-paginate'
import ArrowLeftIcon from 'ui/ArrowLeftIcon'
import ArrowRightIcon from 'ui/ArrowRightIcon'
import '../../../programComonents/pagination/Pagination.css'

const ProgramCardList = () => {
  const {categories,programs,loadingPrograms,activeProgram,word} = useContext(CategoriesContext)
  const [courses, setCourses] = useState([]);
  const [data , setData] = useState(); 
  const [pageNumber , setPageNumber] = useState(0);
  // const [filtered,setFiltered] = useState();
  useEffect(() => {
    const extractedCourses = categories?.flatMap((category) => category.courses);
    if(!word){
      setCourses(extractedCourses);
    }else{
      const filtered = extractedCourses?.filter(
        (item) =>
          item.name?.toLowerCase().includes(word?.toLowerCase())
      )
      setCourses(filtered);
    }
  }, [categories,word]);


  useEffect(()=>{
    programs &&
    setData(programs?.courses?.slice(0,programs?.courses?.length))
    if(word){
      const filtered = programs?.courses?.filter(
        (item) =>
          item.name?.toLowerCase().includes(word?.toLowerCase())
      )
      setData(filtered)
    }
    if(activeProgram === "all"){
      setData(courses?.slice(0,courses?.length))
    }
  },[programs,categories,activeProgram,courses,word])

  const ItemsPerPage = 9; 
  const itemDisplayed = ItemsPerPage * pageNumber; // 6*0  = 0 displayed
  const displayItems = data?.slice(itemDisplayed , itemDisplayed + ItemsPerPage). //0-5  =  6 Items 
                    map((item,index)=>{
                        return(
                          <ProgramCard item={item} key={index} />                              
                        )
                    })
const pageCount = Math.ceil(data?.length / ItemsPerPage); // 12/6 = 2
const changePage = ({selected}) =>{
    setPageNumber(selected)
}



  return (
    <Container>
      <Row>
        {!loadingPrograms && activeProgram !== 'all'&&
          displayItems
        }
        {activeProgram === 'all' &&
            displayItems
        }
        <ReactPaginate 
                previousLabel = {<ArrowLeftIcon />}
                nextLabel = {<ArrowRightIcon/>}
                pageCount={pageCount}
                onPageChange={changePage}

                containerClassName={"Pagination"}
                previousLinkClassName={"btnBaginate"}
                nextLinkClassName={"btnBaginate"}
                disabledClassName={"disable"}
                activeClassName={"btnBaginate btnBaginateActive"}
                />
        {/* <Pagination /> */}
      </Row>
    </Container>
  );
}

export default ProgramCardList