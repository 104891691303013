import React from 'react'
import './ProgramCard.css'
// import progImg from 'assets/images/prog2.png'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const ProgramCard = ({item}) => {
    const { t } = useTranslation(); 

  return (
    <Col lg="4">
        <div className='ProgramCard'>
            <div className='programImg'>
                <img src={item.image ?? imgLogo} alt='programImage'/>
                <div className='hourProg'>
                    <span>{item.duration}</span>
                </div>
            </div>
            <div className='programTxt'>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
            </div>
            <div className='programCardFooter'>
                <div className='programPrice'>
                    <h3>{item.price_after_discount} {t("currency")}</h3>
                    <span>{item.price_before_discount} {t("currency")}</span>
                </div>
                <div className='ProgramBtn'>
                    <a href='##'>{t("RegisterNow")}</a>
                </div>
            </div>
        </div>
    </Col>
  )
}

export default ProgramCard