import React, { useContext, useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import ProgramCard from './ProgramCard'
// import Pagination from 'components/programComonents/pagination/Pagination'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import ReactPaginate from 'react-paginate'
import ArrowLeftIcon from 'ui/ArrowLeftIcon'
import ArrowRightIcon from 'ui/ArrowRightIcon'
// import '../../../programComonents/pagination/Pagination.css'

const ProgramCardList = () => {
  const {categories24,word} = useContext(CategoriesContext)
  const [filteredLast, setFilteredLast] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);  

  const ItemsPerPage = 9; 
  const itemDisplayed = ItemsPerPage * pageNumber; // 6*0  = 0 displayed
  const displayItems = filteredLast?.slice(itemDisplayed , itemDisplayed + ItemsPerPage). //0-5  =  6 Items 
                    map((item,index)=>{
                        return(
                          <ProgramCard item={item} key={index} />                              
                        )
                    })
const pageCount = Math.ceil(filteredLast?.length / ItemsPerPage); // 12/6 = 2
const changePage = ({selected}) =>{
    setPageNumber(selected)
}


useEffect(() => {
  const filtered = categories24?.filter(
    (item) =>
      item.program_name?.toLowerCase().includes(word?.toLowerCase()) ||
      item.description?.toLowerCase().includes(word?.toLowerCase())
  );
  setFilteredLast(filtered || []);
}, [word, categories24]);

useEffect(() => {
  setFilteredLast(categories24 || []);
}, [categories24]);

  return (
    <Container>
      <Row>
        {
          displayItems
        }
        <ReactPaginate 
                previousLabel = {<ArrowLeftIcon />}
                nextLabel = {<ArrowRightIcon/>}
                pageCount={pageCount}
                onPageChange={changePage}

                containerClassName={"Pagination"}
                previousLinkClassName={"btnBaginate"}
                nextLinkClassName={"btnBaginate"}
                disabledClassName={"disable"}
                activeClassName={"btnBaginate btnBaginateActive"}
                />
        {/* <Pagination /> */}
      </Row>
    </Container>
  );
}

export default ProgramCardList