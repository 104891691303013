import React, { useContext, useRef } from 'react'
import './Header.css'
import { Container } from 'react-bootstrap'
import imgLogo from 'assets/images/logo.png'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useTranslation } from 'react-i18next'
const Header = () => {

  
  const menuRef = useRef(null);
  const toggleMenuAction = ()=> menuRef.current.classList.toggle('active_menu');
  const location = useLocation();

  // Access the pathname from the location object
  const currentRoute = location.pathname;


  const { lang,setLang, changeLanguage } = useContext(CategoriesContext);
  const { t } = useTranslation();


  return (
    <header className={`header ${lang === "en" ? 'headerEn' : ''}`}>
      <Container>
        <div className='rside'>
          <div className='imgLogo'>
          <NavLink to={'/'} className={(navClass)=> navClass.isActive ? 'activeNav' : ''}><img src={imgLogo} alt='logo'/></NavLink>
            {/* <img src={imgLogo} alt='logo'/> */}
          </div>
          <div className='navbar navigation' ref={menuRef} onClick={toggleMenuAction}>
            <nav>
              <ul className='menu_nav' onClick={(e)=>e.stopPropagation()}>
                <li><NavLink to={'/'} className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t('Home')}</NavLink></li>
                <li><NavLink to='/about' className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t('about')}</NavLink></li>
                <li><NavLink to='/programs' className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t('trainingPrograms')}</NavLink></li>
                <li><NavLink to='/programs24' className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t('trainingYear')}</NavLink></li>
                <li>
                  <Link className={currentRoute === '/news' || currentRoute === '/Images' || currentRoute.includes("NewDetails") ? 'activeNav' : ''}>{t('MediaCenter')}</Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M13.28 5.96655L8.9333 10.3132C8.41997 10.8266 7.57997 10.8266 7.06664 10.3132L2.71997 5.96655" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <ul className="dropdown-menu">
                    <li><NavLink to='/news' className={(navClass)=> navClass.isActive || currentRoute.includes("NewDetails") ? 'activeNav' : ''}>{t('news')}</NavLink></li>
                    <li><NavLink to='/Images' className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t("Gallery")}</NavLink></li>
                  </ul>
                </li>
                <li>
                  <NavLink to='/ContactUs' className={(navClass)=> navClass.isActive ? 'activeNav' : ''}>{t('contact')}</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className='lside'>
          <div className={`chLang ${lang === 'en'? 'd-none' : 'd-flex'}`} 
          onClick={() => {
              changeLanguage('en');
              setLang('en');
            }}> 
            <span>{lang === "ar" ? 'EN' : 'ع'}</span>
            <i className="ri-global-line"></i>
          </div>
          <div className={`chLang ${lang === 'ar'? 'd-none' : 'd-flex chLangEn'}`}  
          onClick={() => {
              changeLanguage('ar');
              setLang('ar');
            }}> 
            <span>{lang === "ar" ? 'EN' : 'ع'}</span>
            <i className="ri-global-line"></i>
          </div>
          <div className='btnTrain'>
            <Link to='/BeTrainer'>
              {t('beTrainer')} 
            </Link>
          </div>
        </div>
        <div className='menu' onClick={toggleMenuAction}>
          <i className="ri-menu-fill"></i>
        </div>
      </Container>
    </header>
  )
}

export default Header