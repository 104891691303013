import React, { useEffect } from 'react';
import './Programs24.css';
import { Helmet } from 'react-helmet-async';
import CommonSection from 'components/common_section/CommonSection';
import SearchCommon from 'ui/searchCommon/SearchCommon';
import MainLayout from 'components/mainLayout/MainLayout';
import transition from 'transition';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import ProgramCardList from 'components/programs24Components/programCard/ProgramCardList';
const Programs24 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>S.E.T.C || Programs 2024</title>
        <meta
          name="description"
          content="يقدم مركز تعزيز الكفاءات العديد من البرامج التدريبية المصممة باحترافية وبأساليب تفاعلية ،، دورات في العديد من التخصصات عن بعد باداء تدريبي متميز قابل للتطوير يرتقي باحتياجات المتدربين وتلبية متطلبات العصر وتماشيا مع احدث الاساليب ،، اكبر قدر من الفائده المهارية ستحصل عليها من خلال الدورة بالاضافة الى الشهادة المعتمدة وباقل الاسعار و افضل المدربين"
        />
        <link rel="canonical" href="/programs" />
      </Helmet>
      <MainLayout>
        <CommonSection title={t('trainingYear')}>
          <SearchCommon />
        </CommonSection>
        <div className="ProgPage">
          <Container>
            <Row>
              {/* <Col lg="3">
                <ProgramsList />
              </Col> */}
              <Col lg="12">
                <ProgramCardList />
              </Col> 
            </Row>
          </Container>
        </div>
      </MainLayout>
    </>
  );
};

export default transition(Programs24);
