import React from 'react'
import './MainHead.css'
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const MainHead = (props) => {
  return (
    <div className="SuggestHeader">
      <div className="SuggestHeader_text">
        <h2>{props.title}</h2>
        <h4>
          {props.description}
        </h4>
      </div>
      <div className="suggestBtn">
        <Link to={props.link}>{t("ExpolreMore")}</Link>
      </div>
    </div>
  );
}

export default MainHead