import React, { useContext, useRef } from 'react'
import './LastNews.css'
import { Container } from 'react-bootstrap'
import MainHead from 'ui/MainHead/MainHead'
import { Link } from 'react-router-dom'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useTranslation } from 'react-i18next'
import { motion,useScroll, useTransform } from "framer-motion";
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const LastNews = () => {
  const {news} = useContext(CategoriesContext);
  const lastFourElements = news?.slice(-4);
  const firstThreeIndexes = lastFourElements?.slice(0, 3);
  const { t } = useTranslation();


  const ref = useRef(null);
  const {scrollYProgress}=useScroll({
    target: ref,
    offset: ['0 1','1.33 1']
  })
  const scaleProgress = useTransform(scrollYProgress,[0,1],[.8,1])
  const opacityProgress = useTransform(scrollYProgress,[0,1],[.8,1])


  return (
    <div className="LastNews" ref={ref}>
      <Container>
        <MainHead
          title={t("lastNews")}
          description={t("learnLast")}
          link={'/news'}
        />
        <motion.div className="cardsNews" style={{scale: scaleProgress, opacity: opacityProgress}}>
          <div className="NewmainCard">
            {lastFourElements?.slice(-1)?.map((item) => (
              <div key={item.id}>
                <div className="newMainImg">
                  <img src={item.image ? item.image : imgLogo} alt="mian_news_img" />
                </div>
                <div className="newsmain_content">
                  <h6 className="newsmain_content_date d-flex align-items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                    >
                      <path
                        d="M6.66675 2.58398V5.08398"
                        stroke="#B1B1B1"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.3333 2.58398V5.08398"
                        stroke="#B1B1B1"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.91675 8.49231H17.0834"
                        stroke="#B1B1B1"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 8.00065V15.084C17.5 17.584 16.25 19.2507 13.3333 19.2507H6.66667C3.75 19.2507 2.5 17.584 2.5 15.084V8.00065C2.5 5.50065 3.75 3.83398 6.66667 3.83398H13.3333C16.25 3.83398 17.5 5.50065 17.5 8.00065Z"
                        stroke="#B1B1B1"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99632 12.334H10.0038"
                        stroke="#B1B1B1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91185 12.334H6.91933"
                        stroke="#B1B1B1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91185 14.834H6.91933"
                        stroke="#B1B1B1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>{item.date}</span>
                  </h6>
                  <h4>{item.title}</h4>
                  <p>
                    {item.description}
                  </p>
                  <Link to={`/NewDetails/${item.id}`}>{t("seeMore")}</Link>
                </div>
              </div>
            ))}
          </div>
          <div className="newOtherCards">
            {
              firstThreeIndexes?.map((item)=>(
                <div className="newOtherCards_card" key={item.id}>
                  <div className="newOtherCards_card_image">
                    <img src={item.image ? item.image : imgLogo} alt="news_img" />
                  </div>
                  <div className="newsmain_content">
                    <h6 className="newsmain_content_date d-flex align-items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M6.66675 2.58398V5.08398"
                          stroke="#B1B1B1"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M13.3333 2.58398V5.08398"
                          stroke="#B1B1B1"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.91675 8.49231H17.0834"
                          stroke="#B1B1B1"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.5 8.00065V15.084C17.5 17.584 16.25 19.2507 13.3333 19.2507H6.66667C3.75 19.2507 2.5 17.584 2.5 15.084V8.00065C2.5 5.50065 3.75 3.83398 6.66667 3.83398H13.3333C16.25 3.83398 17.5 5.50065 17.5 8.00065Z"
                          stroke="#B1B1B1"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99632 12.334H10.0038"
                          stroke="#B1B1B1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.91185 12.334H6.91933"
                          stroke="#B1B1B1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.91185 14.834H6.91933"
                          stroke="#B1B1B1"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{item.date}</span>
                    </h6>
                    <h4>{item.title}</h4>
                    <p>
                      {item.description}  
                    </p>
                    <Link to={`/NewDetails/${item.id}`}>{t("seeMore")}</Link>
                  </div>
                </div>
              ))
            }
          </div>
        </motion.div>
      </Container>  
    </div>
  );
}

export default LastNews