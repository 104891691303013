import React from 'react'
import './ViewMsg.css'
import { Container } from 'react-bootstrap'
import view from 'assets/images/view.png'
import msg from 'assets/images/msg.png'
import { useTranslation } from 'react-i18next'
const ViewMsg = () => {
    const { t } = useTranslation();

  return (
    <>
        <div className='ViewMsg'>
            <Container>
                <div className='vmsgContainer'>
                    <div className='vmsgCards'>
                        <div className='vmsgCard'>
                            <div className='vImg'>
                                <img src={view} alt='viewImage'/>
                            </div>
                            <h2>{t("OurVision")}</h2>
                            <h4>{t("Distinguishedprofessionalservice")}</h4>
                        </div>
                        <div className='vmsgCard'>
                            <div className='vImg'>
                                <img src={msg} alt='viewImage'/>
                            </div>
                            <h2>{t("OurMessage")}</h2>
                            <h4>{t("tobehouse")}</h4>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </>
  )
}

export default ViewMsg