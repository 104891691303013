import React, { useEffect } from 'react'
import './Home.css';
import MainLayout from 'components/mainLayout/MainLayout';
import Hero from 'components/homeComponents/heroSection/Hero';
import FavUs from 'components/homeComponents/favUs/FavUs';
import SuggestProgram from 'components/homeComponents/suggestProgram/SuggestProgram';
import LastNews from 'components/homeComponents/lastNews/LastNews';
import Reviews from 'components/homeComponents/reviews/Reviews';
import ImagesSlider from 'components/homeComponents/imagesSlider/ImagesSlider';
import transition from 'transition';
import { Helmet } from 'react-helmet-async';


const Home = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
},[])

  return (
    <>
        <Helmet>
          <title>S.E.T.C || HOME</title>
          <meta
            name="description"
            content="مركز تعزيز المهارات للتدريب قم بتعزيز مهاراتك 
مع برامجنا المميزة برامج و خدمات تدريبية وفق أحدث المعايير العالمية
تقودك الي التغيير الايجابي و التميز في الاداء"
          />
          <link rel='canonical' href='/home'/>
          <link rel='canonical' href='/'/>
        </Helmet>
      <MainLayout>
        <Hero />
        <FavUs />
        <SuggestProgram />
        <LastNews />
        <ImagesSlider />
        <Reviews />
      </MainLayout>
    </>
  );
}

export default transition(Home)