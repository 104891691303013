import React from 'react'
import './Qema.css'
import { Container } from 'react-bootstrap'
import NumLi from 'ui/numLi/NumLi'
import { useTranslation } from 'react-i18next'

const Qema = () => {
    const { t } = useTranslation();

  return (
    <div className='Qema'>
        <Container>
            <div className='qemaContainer'>
                <h2>{t("Ourvalue")}</h2>
                <ul>
                    <li>
                        <NumLi num='1'/>
                        <h4>
                            <span>{t("Commitment")}: </span>
                            {t("Wearecommitted")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='2'/>
                        <h4>
                            <span>{t("responsibility")}: </span>
                            {t("performourtasks")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='3'/>
                        <h4>
                            <span>{t("Professionalism")}: </span>
                            {t("carryout")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='4'/>
                        <h4>
                            <span>{t("Transparency")}: </span>
                            {t("Wedeal")}
                        </h4>
                    </li>
                    <li>
                        <NumLi num='5'/>
                        <h4>
                            <span>{t("Responsibility")}: </span>
                            {t("supportingcommunity")}
                        </h4>
                    </li>
                </ul>
            </div>
        </Container>
    </div>
  )
}

export default Qema