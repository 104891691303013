import React from 'react'
import './Footer.css'
import logo2 from 'assets/images/logo2.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div>
        <div className='footerContainer'>
          <div className='fonterContent'>
          <Link to='/'><img src={logo2} alt='logo'/></Link>
            <p>{t("ProvidingDistinguished")}</p>
          </div>
          <div className='footerLink'>
            <h5>{t("QuickLinks")}</h5>
            <div className='footerLinks'>
              <div className='links1'>
                <ul>
                  <li><Link to='/'>{t("Home")}</Link></li>
                  <li><Link to='/about'>{t("about")}</Link></li>
                  <li><Link to='/programs'>{t("trainingPrograms")}</Link></li>
                </ul>
              </div>
              <div className='links1'>
                <ul>
                  <li><Link to='/news'>{t("news")}</Link></li>
                  <li><Link to='/Images'>{t("Gallery")}</Link></li>
                  <li><Link to='/ContactUs'>{t("contact")}</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footerForm'>
            <h5>{t("Subscribe")}</h5>
            <form>
              <div className='inputMail'>
                <input type='email' placeholder={t("mailHolder")}/>
                <button type='submit'>{t("subscribeNow")}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer