import React, { useState } from 'react'
import './ContactForm.css'
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const ContactForm = () => {
    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [subject,setSubject] = useState();
    const [content,setContent] = useState();

    const submitForm = async(e)=>{
        e.preventDefault();
        try{
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('subject', subject);
            formData.append('content', content);
            const res = await fetch("https://t3zez.poultrystore.net/api/contact/create", {
                method: "POST",
                headers: {
                    'apiSecret': "MDb#jfr7823$jd"
                },
                body: formData
            });
            const data = await res.json(); 
            if (data.success) {
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }  catch (error) {
            toast.error("An error occurred while submitting the form");
        }
    }
    const { t } = useTranslation(); 

  return (
    <div className="ContactForm" onSubmit={submitForm}>
      <h3>{t("contact")}</h3>
      <form>
        <input type='text' placeholder={t("nameHolder")} id='name' required onChange={(e)=>setName(e.target.value)}/>
        <input type='email' placeholder={t("mailHolder")} id='mail' required onChange={(e)=>setEmail(e.target.value)}/>
        <input type='text' placeholder={t("subject")} id='subject' required onChange={(e)=>setSubject(e.target.value)}/>
        <textarea placeholder={t("message")} id='message' required onChange={(e)=>setContent(e.target.value)}/>
        <button type='submit'>{t("send")}</button>
      </form>
    </div>
  );
}

export default ContactForm