import React from 'react'
import './ImagesSlider.css'
import { Link } from 'react-router-dom'
import Example from './Example'
import { useTranslation } from 'react-i18next';


const ImagesSlider = () => {
  const { t } = useTranslation();

  return (
    <div className='ImagesSlider'>
        <h2>{t("imageSlider")}</h2>
        
        <div className='sliderImages'>
          <Example/>
        </div>

        <div className="suggestBtn text-center mt-5">
            <Link to="/Images">{t("ExpolreMore")}</Link> 
      </div>
    </div>
  )
}

export default ImagesSlider