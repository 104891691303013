import React from 'react'
import './Ads.css'
import ad from 'assets/images/ad.png'
const Ads = () => {
  return (
    <div className='ads'>
        <div className='headAd'>
            <span>اعلان</span>
        </div>
        <div className='image_ad'>
            <img src={ad} alt='adImage'/>
        </div>
        <div className='adContent'>
            <h4>كريستال عصفور</h4>
            <h6>ملاذ طبيعي من الجمال الكريستالي المتلألئ مثل النجوم ينبض بالحياة من حولك. قم بزيارة جناحنا في DownTown Event في منطقة دبي للتصميم (جناح رقم - B02</h6>
        </div>
    </div>
  )
}

export default Ads