import { CategoriesContext } from "contextApi/CategoriesContext";
import React, { useContext, useEffect, useState } from "react";
// import { Overlay } from "react-bootstrap";
import { config } from "react-spring";
import Carousel from "react-spring-3d-carousel"; 
// import uuidv4 from "uuid";
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

function Example() { 
    const {news} = useContext(CategoriesContext)
    const [goToSlide, setGoToSlide] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [offsetRadius, setOffsetRadius] = useState(2);
    // eslint-disable-next-line no-unused-vars
    const [configValue, setConfigValue] = useState(config.gentle);
    const [slides, setSlides] = useState([]);
    // const [showNavigation, setShowNavigation] = useState(true);

    useEffect(() => {
      // Assuming `data` is available
      news &&
      setSlides(
        news?.map((item) => ({
          content: <img src={item?.image ?? imgLogo} alt={item?.title} key={item?.id} />
        }))?.map((slide, index) => ({ ...slide, onClick: () => setGoToSlide(index) }))
      );
    }, [news]);
    // const [index, setIndex] = useState(0);
    // const [isAutoplayed, setIsAutoplayed] = useState(false);
    // const autoPlayRef = useRef();

    // useEffect(() => {
    //     clearInterval(autoPlayRef.current);
    //     if (isAutoplayed) {
    //       autoPlayRef.current = setInterval(() => {
    //         setIndex((index) => index + 1);
    //       }, 1000);
    //     }
    //     return () => clearInterval(autoPlayRef.current);
    //   }, [isAutoplayed]);
    return (
      <div style={{ width: "80%", height: "500px", margin: "0 auto" ,direction:"ltr"}}>
        <Carousel
          slides={slides}
          goToSlide={goToSlide}
          offsetRadius={offsetRadius}
        //   showNavigation={showNavigation}
          animationConfig={configValue}
        />
        <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "16px"
        }}
      >
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setGoToSlide(goToSlide - 1);
          }}
          className="prevSlide"
        >
        <i className="ri-arrow-left-s-line"></i>
        </span>
        {/* <div>
          <label for="autoplay">Autoplay?</label>
          <input
            id="autoplay"
            type="checkbox"
            checked={isAutoplayed}
            onChange={() => {
              setIsAutoplayed(!isAutoplayed);
            }}
          />{" "}
        </div> */}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setGoToSlide(goToSlide + 1);
          }}
          className="nextSlide"
        >
        <i className="ri-arrow-right-s-line"></i>
        </span>
      </div>
        {/* <div
          style={{
            margin: "0 auto",
            marginTop: "2rem",
            width: "50%",
            display: "flex",
            justifyContent: "space-around",
            Overlay:"hidden"
          }}
        >
        </div> */}
      </div>
    );
  }
export default Example