import React from 'react'
import './ProgramCard.css'
// import progImg from 'assets/images/prog2.png'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const ProgramCard = ({item}) => {
    const { t } = useTranslation(); 

  return (
    <Col lg="4">
        <div className='ProgramCard'>
            <div className='programImg'>
                <img src={item.image ?? imgLogo} alt='programImage'/>
                {/* <div className='hourProg'>
                    <span>{item.duration} {t("hour")}</span>
                </div> */}
            </div>
            <div className='programTxt program24Card'>
                <h4><span>{t("programName24")}: </span>{item.program_name}</h4>
                <h4><span>{t("trainername")}: </span>{item.trainer_name}</h4>
                <h4>
                    <span>{t("times")}: </span>
                    {
                        item?.appointments?.length > 0 && item?.appointments?.length === 1 ?
                    item.appointments?.map((time,index)=>(
                        time.day + ':' + time.time
                    ))
                        :
                        item?.appointments?.length > 1 ?
                        item.appointments?.map((time,index)=>(

                            time.day + ':' + time.time + (index != item?.appointments?.length-1 ?  ',' : '') 
                        ))
                        : ""
                }
                </h4>
                <h4><span>{t("startdate")}: </span>{item.start_date}</h4>
                <h4><span>{t("cost24")}: </span> {item.cost} {t("currency")}</h4>
                <h4><span>{t("about24")}: </span></h4>
                <p>{item.description}</p>
            </div>
            <div className='programCardFooter'>
                {/* <div className='programPrice'>
                    <h3>{item.price_after_discount} {t("currency")}</h3>
                    <span>{item.price_before_discount} {t("currency")}</span>
                </div> */}
                <div className='ProgramBtn'>
                    <a href='##'>{t("RegisterNow")}</a>
                </div>
            </div>
        </div>
    </Col>
  )
}

export default ProgramCard