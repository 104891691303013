import React from 'react'
import './CommonSection.css'
import { Container } from 'react-bootstrap'
const CommonSection = (props) => {
  return (
    <div className='CommonSection d-flex align-items-center'>
      <Container className='d-flex align-items-center justify-content-between'>
        <div className='titleCommon'>
          <h2>{props.title}</h2>
        </div>
        {props.children}
      </Container>
    </div>
  )
}

export default CommonSection