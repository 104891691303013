import React, { useContext } from 'react';
import phone from 'assets/images/phoneCall.png';
import './ContactInfo.css';
import { useTranslation } from 'react-i18next';
import { CategoriesContext } from 'contextApi/CategoriesContext';
const ContactInfo = () => {
  const { t } = useTranslation();
  const { lang } = useContext(CategoriesContext);

  return (
    <div className="ContactInfo">
      <h3>{t('Contactinfo')}</h3>
      <ul>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="21"
            viewBox="0 0 14 21"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.53162 0.987186C5.71648 -0.282311 8.40203 -0.260122 10.5664 1.04531C12.7095 2.37733 14.012 4.75459 13.9998 7.31186C13.95 9.85235 12.5533 12.2404 10.8075 14.0865C9.79983 15.1568 8.67261 16.1032 7.44884 16.9065C7.32281 16.9793 7.18475 17.0281 7.04148 17.0504C6.9036 17.0445 6.76932 17.0038 6.65075 16.9319C4.78243 15.725 3.14334 14.1845 1.81233 12.3844C0.698589 10.8818 0.0658357 9.06642 1.16173e-06 7.18483C-0.00144468 4.62266 1.34677 2.25668 3.53162 0.987186ZM4.79427 8.24519C5.1618 9.15125 6.02929 9.74225 6.99171 9.74226C7.62221 9.74679 8.2283 9.49424 8.67492 9.04089C9.12154 8.58753 9.37159 7.97105 9.36936 7.32879C9.37272 6.34844 8.79557 5.46272 7.90737 5.08518C7.01918 4.70764 5.99515 4.91276 5.31343 5.60476C4.6317 6.29676 4.42675 7.33913 4.79427 8.24519Z"
              fill="#287660"
            />
            <ellipse
              opacity="0.4"
              cx="7"
              cy="19.0504"
              rx="5"
              ry="1"
              fill="#287660"
            />
          </svg>
          <span style={{ textAlign: lang === 'ar' ? 'right' : 'left' }}>
            {t('address')} : <br />
            {t('Kingdom')}
          </span>
        </li>
        <li>
          <img src={phone} alt="calling" />
          <a href="tel:00966506551375" style={{ textDecoration: 'none' }}>
            <span>
              {t('phoneT')} :{' '}
              {lang === 'ar' ? '00966506551375' : '00966506551375'}
            </span>
          </a>
        </li>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              opacity="0.4"
              d="M20 10.0504C20 15.5744 15.523 20.0504 10 20.0504C4.477 20.0504 0 15.5744 0 10.0504C0 4.52842 4.477 0.050415 10 0.050415C15.523 0.050415 20 4.52842 20 10.0504Z"
              fill="#287660"
            />
            <path
              d="M13.5739 13.865C13.4429 13.865 13.3109 13.831 13.1899 13.76L9.26393 11.418C9.03793 11.282 8.89893 11.037 8.89893 10.773V5.72595C8.89893 5.31195 9.23493 4.97595 9.64893 4.97595C10.0629 4.97595 10.3989 5.31195 10.3989 5.72595V10.347L13.9589 12.47C14.3139 12.683 14.4309 13.143 14.2189 13.499C14.0779 13.734 13.8289 13.865 13.5739 13.865Z"
              fill="#287660"
            />
          </svg>
          <span>
            {t('timesWork')} : {t('fromTime')}
          </span>
        </li>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
          >
            <path
              opacity="0.4"
              d="M20 12.9904C20 15.7804 17.76 18.0404 14.97 18.0504H14.96H5.05C2.27 18.0504 0 15.8004 0 13.0104V13.0004C0 13.0004 0.006 8.57441 0.014 6.34841C0.015 5.93041 0.495 5.69641 0.822 5.95641C3.198 7.84141 7.447 11.2784 7.5 11.3234C8.21 11.8924 9.11 12.2134 10.03 12.2134C10.95 12.2134 11.85 11.8924 12.56 11.3124C12.613 11.2774 16.767 7.94341 19.179 6.02741C19.507 5.76641 19.989 6.00041 19.99 6.41741C20 8.62641 20 12.9904 20 12.9904Z"
              fill="#287660"
            />
            <path
              d="M19.4759 2.72393C18.6099 1.09193 16.9059 0.0499268 15.0299 0.0499268H5.04988C3.17388 0.0499268 1.46988 1.09193 0.603884 2.72393C0.409884 3.08893 0.501884 3.54393 0.824884 3.80193L8.24988 9.74093C8.76988 10.1609 9.39988 10.3699 10.0299 10.3699C10.0339 10.3699 10.0369 10.3699 10.0399 10.3699C10.0429 10.3699 10.0469 10.3699 10.0499 10.3699C10.6799 10.3699 11.3099 10.1609 11.8299 9.74093L19.2549 3.80193C19.5779 3.54393 19.6699 3.08893 19.4759 2.72393Z"
              fill="#287660"
            />
          </svg>
          <a
            href="mailto:ghms2021@gmail.com"
            style={{ textDecoration: 'none' }}
          >
            <span>{t('mail')}: ghms2021@gmail.com</span>
          </a>
        </li>
        {/* <li>
          <i
            className="ri-presentation-fill"
            style={{ fontSize: '24px', color: '#287761' }}
          ></i>
          <span>
            {t('trainers')}: محمد صالح الغامدي ، عادل حمد الدوسري ، صالح سالم
            القريشي
          </span>
        </li> */}
      </ul>
    </div>
  );
};

export default ContactInfo;
