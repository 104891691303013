import React, { useEffect } from 'react'
import './BeTrainer.css'
import MainLayout from 'components/mainLayout/MainLayout'
import CommonSection from 'components/common_section/CommonSection'
import { Col, Container, Row } from 'react-bootstrap'
import ContactInfo from 'components/contactUsComponents/ContactInfo/ContactInfo'
import BeTrainerImg from 'assets/images/BeTrainer.png'
import TrainerForm from 'components/BeTrainerComponents/BeTrainerForm/TrainerForm'
import { useTranslation } from 'react-i18next'
import transition from 'transition'
import { Helmet } from 'react-helmet-async'
const BeTrainer = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const { t } = useTranslation(); 

  return (
    <>
        <Helmet> 
      <title>S.E.T.C || JOIN-NOW</title>
            <meta
              name="description"
                content='انضم لفريقنا الان لتصبح مدربا ،، ارسل لنا سيرتك الذاتيه وبياناتك وسيتم مراجعتها في الحال'
            />
            <link rel='canonical' href='/BeTrainer'/>
    </Helmet>
        <MainLayout>
            <CommonSection title={t("JoinUs")}>
                <img src={BeTrainerImg} alt='ContactUs_image' className='ContactUsImg BeTrainImg'/>
            </CommonSection>
            <div className='contactusContent'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <TrainerForm/>
                        </Col>
                        <Col lg="6">
                            <ContactInfo/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainLayout>
    </>
  )
}

export default transition(BeTrainer)