import React, { useContext } from 'react'
import './MoreNews.css'
// import new1 from 'assets/images/news2.png'
import { Link } from 'react-router-dom'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useTranslation } from 'react-i18next'
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const MoreNews = () => {
    const {news} = useContext(CategoriesContext);
    const lastThreeElements = news?.slice(-3);
    const { t } = useTranslation(); 

  return (
    <div className='MoreNews'>
        {lastThreeElements?.map((item)=>(
            <div className='MoreNews_card' key={item?.id}>
                <div className='MoreNews_card_img'>
                    <img src={item?.image ?? imgLogo} alt='newImg'/>
                </div>
                <div className='MoreNews_card_content'>
                    <div className='MoreNews_card_content_date'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M6.66663 1.66663V4.16663" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.3334 1.66663V4.16663" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.91663 7.57495H17.0833" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.5 7.08329V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V7.08329C2.5 4.58329 3.75 2.91663 6.66667 2.91663H13.3333C16.25 2.91663 17.5 4.58329 17.5 7.08329Z" stroke="#B1B1B1" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.9962 11.4167H10.0037" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.91197 11.4167H6.91945" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.91197 13.9167H6.91945" stroke="#B1B1B1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>{item?.date}</span>
                    </div>
                    <h4>{(item?.title).split(' ').slice(0, 4).join(' ')}...</h4>
                    <p>{item?.description}</p>
                    <Link to={`/NewDetails/${item?.id}`}>{t("seeMore")}</Link>
                </div>
            </div>
        ))}
    </div>
  )
}

export default MoreNews