import React from 'react'
import './ImageCard.css'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import new1 from 'assets/images/news3.png'
import imgLogo from 'assets/images/heroImg.9e684023b10019f816a4.png'

const ImageCard = ({item}) => {
  return (
    <Col lg="4">
      <Link to={`/NewDetails/${item.id}`}>
        <div className='ImageCard'>
            <img src={item?.image ?? imgLogo} alt='images_new'/>
            <div className='imageContent'>
              <p>{item?.description}</p>
            </div>
        </div>
      </Link>
    </Col>
  )
}

export default ImageCard