import React from 'react'

const ArrowRightIcon = () => {
  return (
    <div className="btnBaginate">
      <span>
        <i className="ri-arrow-right-s-line"></i>
      </span>
    </div>
  );
}

export default ArrowRightIcon