import React, { useEffect } from 'react'
import './News.css'
import MainLayout from 'components/mainLayout/MainLayout'
import CommonSection from 'components/common_section/CommonSection'
import SearchCommon from 'ui/searchCommon/SearchCommon'
import { Col, Container, Row } from 'react-bootstrap'
import NewsSide from 'components/newsComponents/NewsSide/NewsSide'
import Ads from 'components/newsComponents/AdsSide/Ads'
import { useTranslation } from 'react-i18next'
import transition from 'transition'
import { Helmet } from 'react-helmet-async'
const News = () => {
        useEffect(()=>{
          window.scrollTo(0,0)
      },[])
      const { t } = useTranslation(); 

  return (
    <>
    <Helmet>
        <title>S.E.T.C || NEWS</title>
          <meta
            name="description"
            content="تعرف علي اخر الاخبار و الفاعليات و الاعلانات لمركز تعزيز المهارات للتدريب"
        />
          <link rel='canonical' href='/news'/>
    </Helmet>
        <MainLayout>
            <CommonSection title={t("lastNews")}>
                <SearchCommon/>
            </CommonSection>
            <div className='newsPage'>
                <Container>
                    <Row>
                        <Col lg="8">
                            <NewsSide/>
                        </Col>
                        <Col lg="4">
                            <Ads/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainLayout>
    </>
  )
}

export default transition(News)