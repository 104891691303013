import React, { useContext } from 'react'
import { Row } from 'react-bootstrap'
import ImageCard from './ImageCard'
// import Pagination from 'components/programComonents/pagination/Pagination'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import { useState } from 'react'
import { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import ArrowLeftIcon from 'ui/ArrowLeftIcon'
import ArrowRightIcon from 'ui/ArrowRightIcon'

const ImageList = () => {



  const { news } = useContext(CategoriesContext);
  const { word } = useContext(CategoriesContext);
  const [filteredNews, setFilteredNews] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const ItemsPerPage = 6;
  const itemDisplayed = ItemsPerPage * pageNumber;
  const displayItems = filteredNews
    ?.slice(itemDisplayed, itemDisplayed + ItemsPerPage)
    .map((item) =><ImageCard item={item} key={item?.id}/>);

  const pageCount = Math.ceil(filteredNews?.length / ItemsPerPage);
  
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filtered = news?.filter(
      (item) =>
        item.description?.toLowerCase().includes(word?.toLowerCase())
    );
    setFilteredNews(filtered || []);
  }, [word, news]);

  useEffect(() => {
    setFilteredNews(news || []);
  }, [news]);

  return (
    <Row>
      {displayItems}
        <div className='paginateImg'>
            <ReactPaginate 
                previousLabel = {<ArrowLeftIcon />}
                nextLabel = {<ArrowRightIcon/>}
                pageCount={pageCount}
                onPageChange={changePage}

                containerClassName={"Pagination"}
                previousLinkClassName={"btnBaginate"}
                nextLinkClassName={"btnBaginate"}
                disabledClassName={"disable"}
                activeClassName={"btnBaginate btnBaginateActive"}
                />
            {/* <Pagination/> */}
        </div>
    </Row>
  )
}

export default ImageList