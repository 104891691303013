import React, { useRef } from 'react'
import './Reviews.css'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { motion,useScroll, useTransform } from "framer-motion";

const Reviews = () => {
  const { t } = useTranslation();

  const ref = useRef(null);
  const {scrollYProgress}=useScroll({
    target: ref,
    offset: ['0 1','1.33 1']
  })
  const scaleProgress = useTransform(scrollYProgress,[0,1],[.8,1])
  const opacityProgress = useTransform(scrollYProgress,[0,1],[.8,1])
  return (
    <div className="Reviews">
      <div className="reviews_header text-center">
        <h2>{t("TraineeReview")}</h2>
        <p className='text-center'>
          {t("Ourtraineerare")}
          <br />
          {t("Wearekeen")}</p>
      </div>
      <Container>
        <div className="review_cards" ref={ref}>
          <motion.div className="review_card" style={{scale: scaleProgress, opacity: opacityProgress}}>
            <div className="iconRev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43"
                height="32"
                viewBox="0 0 43 32"
                fill="none"
              >
                <path
                  d="M11.8516 0.559204H7.11087C3.18964 0.559204 -0.000244141 3.52668 -0.000244141 7.17451V11.5847C-0.000244141 15.2325 3.18964 18.2 7.11087 18.2H11.8516C12.6127 18.2 13.3323 18.059 14.0203 17.8519C13.0646 23.0455 8.22352 27.0204 2.37013 27.0204C1.05994 27.0204 -0.000244141 28.0067 -0.000244141 29.2255C-0.000244141 30.4443 1.05994 31.4306 2.37013 31.4306C11.5183 31.4306 18.9627 24.5053 18.9627 15.9949V7.17451C18.9627 3.52668 15.7728 0.559204 11.8516 0.559204ZM35.5553 0.559204H30.8146C26.8933 0.559204 23.7035 3.52668 23.7035 7.17451V11.5847C23.7035 15.2325 26.8933 18.2 30.8146 18.2H35.5553C36.3164 18.2 37.036 18.059 37.724 17.8519C36.7683 23.0455 31.9272 27.0204 26.0738 27.0204C24.7636 27.0204 23.7035 28.0067 23.7035 29.2255C23.7035 30.4443 24.7636 31.4306 26.0738 31.4306C35.222 31.4306 42.6664 24.5053 42.6664 15.9949V7.17451C42.6664 3.52668 39.4765 0.559204 35.5553 0.559204Z"
                  fill="#20B486"
                />
                <path
                  d="M14.5121 17.9424L14.6603 17.1371L13.8762 17.3731C13.22 17.5707 12.5504 17.7 11.8516 17.7H7.11087C3.43114 17.7 0.499756 14.923 0.499756 11.5847V7.17451C0.499756 3.83624 3.43114 1.0592 7.11087 1.0592H11.8516C15.5313 1.0592 18.4627 3.83624 18.4627 7.17451V15.9949C18.4627 24.1957 11.2768 30.9306 2.37013 30.9306C1.30144 30.9306 0.499756 30.1348 0.499756 29.2255C0.499756 28.3162 1.30144 27.5204 2.37013 27.5204C8.44587 27.5204 13.5094 23.3914 14.5121 17.9424ZM38.2158 17.9424L38.364 17.1371L37.5799 17.3731C36.9237 17.5707 36.2541 17.7 35.5553 17.7H30.8146C27.1348 17.7 24.2035 14.923 24.2035 11.5847V7.17451C24.2035 3.83624 27.1348 1.0592 30.8146 1.0592H35.5553C39.235 1.0592 42.1664 3.83624 42.1664 7.17451V15.9949C42.1664 24.1957 34.9805 30.9306 26.0738 30.9306C25.0051 30.9306 24.2035 30.1348 24.2035 29.2255C24.2035 28.3162 25.0051 27.5204 26.0738 27.5204C32.1496 27.5204 37.2131 23.3914 38.2158 17.9424Z"
                  stroke="black"
                  strokeOpacity="0.1"
                />
              </svg>
            </div>
            <p className="content_rev">
              كانت دورة رائعه غنية بالمعلومات ، حيث اتبع المدرب اسلوب الامثله
              الواقعيه و المهنيه في شرح المفاهيم و الافكار لضمان الفهم الكامل
              لها
            </p>
            <h6>احمد معيلي</h6>
          </motion.div>
          <motion.div className="review_card" style={{scale: scaleProgress, opacity: opacityProgress}}>
            <div className="iconRev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43"
                height="32"
                viewBox="0 0 43 32"
                fill="none"
              >
                <path
                  d="M11.8516 0.559204H7.11087C3.18964 0.559204 -0.000244141 3.52668 -0.000244141 7.17451V11.5847C-0.000244141 15.2325 3.18964 18.2 7.11087 18.2H11.8516C12.6127 18.2 13.3323 18.059 14.0203 17.8519C13.0646 23.0455 8.22352 27.0204 2.37013 27.0204C1.05994 27.0204 -0.000244141 28.0067 -0.000244141 29.2255C-0.000244141 30.4443 1.05994 31.4306 2.37013 31.4306C11.5183 31.4306 18.9627 24.5053 18.9627 15.9949V7.17451C18.9627 3.52668 15.7728 0.559204 11.8516 0.559204ZM35.5553 0.559204H30.8146C26.8933 0.559204 23.7035 3.52668 23.7035 7.17451V11.5847C23.7035 15.2325 26.8933 18.2 30.8146 18.2H35.5553C36.3164 18.2 37.036 18.059 37.724 17.8519C36.7683 23.0455 31.9272 27.0204 26.0738 27.0204C24.7636 27.0204 23.7035 28.0067 23.7035 29.2255C23.7035 30.4443 24.7636 31.4306 26.0738 31.4306C35.222 31.4306 42.6664 24.5053 42.6664 15.9949V7.17451C42.6664 3.52668 39.4765 0.559204 35.5553 0.559204Z"
                  fill="#20B486"
                />
                <path
                  d="M14.5121 17.9424L14.6603 17.1371L13.8762 17.3731C13.22 17.5707 12.5504 17.7 11.8516 17.7H7.11087C3.43114 17.7 0.499756 14.923 0.499756 11.5847V7.17451C0.499756 3.83624 3.43114 1.0592 7.11087 1.0592H11.8516C15.5313 1.0592 18.4627 3.83624 18.4627 7.17451V15.9949C18.4627 24.1957 11.2768 30.9306 2.37013 30.9306C1.30144 30.9306 0.499756 30.1348 0.499756 29.2255C0.499756 28.3162 1.30144 27.5204 2.37013 27.5204C8.44587 27.5204 13.5094 23.3914 14.5121 17.9424ZM38.2158 17.9424L38.364 17.1371L37.5799 17.3731C36.9237 17.5707 36.2541 17.7 35.5553 17.7H30.8146C27.1348 17.7 24.2035 14.923 24.2035 11.5847V7.17451C24.2035 3.83624 27.1348 1.0592 30.8146 1.0592H35.5553C39.235 1.0592 42.1664 3.83624 42.1664 7.17451V15.9949C42.1664 24.1957 34.9805 30.9306 26.0738 30.9306C25.0051 30.9306 24.2035 30.1348 24.2035 29.2255C24.2035 28.3162 25.0051 27.5204 26.0738 27.5204C32.1496 27.5204 37.2131 23.3914 38.2158 17.9424Z"
                  stroke="black"
                  strokeOpacity="0.1"
                />
              </svg>
            </div>
            <p className="content_rev">
              كانت دورة رائعه غنية بالمعلومات ، حيث اتبع المدرب اسلوب الامثله
              الواقعيه و المهنيه في شرح المفاهيم و الافكار لضمان الفهم الكامل
              لها
            </p>
            <h6>احمد معيلي</h6>
          </motion.div>
          <motion.div className="review_card" style={{scale: scaleProgress, opacity: opacityProgress}}>
            <div className="iconRev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43"
                height="32"
                viewBox="0 0 43 32"
                fill="none"
              >
                <path
                  d="M11.8516 0.559204H7.11087C3.18964 0.559204 -0.000244141 3.52668 -0.000244141 7.17451V11.5847C-0.000244141 15.2325 3.18964 18.2 7.11087 18.2H11.8516C12.6127 18.2 13.3323 18.059 14.0203 17.8519C13.0646 23.0455 8.22352 27.0204 2.37013 27.0204C1.05994 27.0204 -0.000244141 28.0067 -0.000244141 29.2255C-0.000244141 30.4443 1.05994 31.4306 2.37013 31.4306C11.5183 31.4306 18.9627 24.5053 18.9627 15.9949V7.17451C18.9627 3.52668 15.7728 0.559204 11.8516 0.559204ZM35.5553 0.559204H30.8146C26.8933 0.559204 23.7035 3.52668 23.7035 7.17451V11.5847C23.7035 15.2325 26.8933 18.2 30.8146 18.2H35.5553C36.3164 18.2 37.036 18.059 37.724 17.8519C36.7683 23.0455 31.9272 27.0204 26.0738 27.0204C24.7636 27.0204 23.7035 28.0067 23.7035 29.2255C23.7035 30.4443 24.7636 31.4306 26.0738 31.4306C35.222 31.4306 42.6664 24.5053 42.6664 15.9949V7.17451C42.6664 3.52668 39.4765 0.559204 35.5553 0.559204Z"
                  fill="#20B486"
                />
                <path
                  d="M14.5121 17.9424L14.6603 17.1371L13.8762 17.3731C13.22 17.5707 12.5504 17.7 11.8516 17.7H7.11087C3.43114 17.7 0.499756 14.923 0.499756 11.5847V7.17451C0.499756 3.83624 3.43114 1.0592 7.11087 1.0592H11.8516C15.5313 1.0592 18.4627 3.83624 18.4627 7.17451V15.9949C18.4627 24.1957 11.2768 30.9306 2.37013 30.9306C1.30144 30.9306 0.499756 30.1348 0.499756 29.2255C0.499756 28.3162 1.30144 27.5204 2.37013 27.5204C8.44587 27.5204 13.5094 23.3914 14.5121 17.9424ZM38.2158 17.9424L38.364 17.1371L37.5799 17.3731C36.9237 17.5707 36.2541 17.7 35.5553 17.7H30.8146C27.1348 17.7 24.2035 14.923 24.2035 11.5847V7.17451C24.2035 3.83624 27.1348 1.0592 30.8146 1.0592H35.5553C39.235 1.0592 42.1664 3.83624 42.1664 7.17451V15.9949C42.1664 24.1957 34.9805 30.9306 26.0738 30.9306C25.0051 30.9306 24.2035 30.1348 24.2035 29.2255C24.2035 28.3162 25.0051 27.5204 26.0738 27.5204C32.1496 27.5204 37.2131 23.3914 38.2158 17.9424Z"
                  stroke="black"
                  strokeOpacity="0.1"
                />
              </svg>
            </div>
            <p className="content_rev">
              كانت دورة رائعه غنية بالمعلومات ، حيث اتبع المدرب اسلوب الامثله
              الواقعيه و المهنيه في شرح المفاهيم و الافكار لضمان الفهم الكامل
              لها
            </p>
            <h6>احمد معيلي</h6>
          </motion.div>
        </div>
      </Container>
    </div>
  );
}

export default Reviews