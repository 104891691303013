import CommonSection from 'components/common_section/CommonSection'
import MainLayout from 'components/mainLayout/MainLayout'
import React, { useEffect } from 'react'
import ContactUsImg from 'assets/images/contactUs.png'
import './ContactUs.css'
import { Col, Container, Row } from 'react-bootstrap'
import ContactForm from 'components/contactUsComponents/ContactForm/ContactForm'
import ContactInfo from 'components/contactUsComponents/ContactInfo/ContactInfo'
import { useTranslation } from 'react-i18next'
import transition from 'transition'
import { Helmet } from 'react-helmet-async'
const ContactUs = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    const { t } = useTranslation(); 

  return (
    <>
    <Helmet> 
      <title>S.E.T.C || CONTACT-US</title>
            <meta
              name="description"
                content='قم بالتواصل معنا الان وارسل لنا رسالتك'
            />
            <link rel='canonical' href='/ContactUs'/>
    </Helmet>
        <MainLayout>
            <CommonSection title={t("contact")}>
                <img src={ContactUsImg} alt='ContactUs_image' className='ContactUsImg'/>
            </CommonSection>
            <div className='contactusContent'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <ContactForm/>
                        </Col>
                        <Col lg="6">
                            <ContactInfo/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MainLayout>
    </>
  )
}

export default transition(ContactUs)