import React, { useContext } from 'react'
import './SearchCommon.css'
import { useTranslation } from 'react-i18next';
import { CategoriesContext } from 'contextApi/CategoriesContext';
const SearchCommon = () => {
  const { t } = useTranslation();
  const {setWord}= useContext(CategoriesContext);

  return (
    <div className="formSearch">
      <form onSubmit={(e)=>e.preventDefault()}>
        <div className="inputMail">
          <input type="text" placeholder={t("searchHolder")} onChange={(e)=>setWord(e.target.value)}/>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
              stroke="#B1B1B1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 22L20 20"
              stroke="#B1B1B1"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </form>
    </div>
  );
}

export default SearchCommon