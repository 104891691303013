import React, { useContext, useEffect, useState } from 'react'
import './News.css'
import NewCard from './NewsCards/NewCard'
import { Row } from 'react-bootstrap'
// import Pagination from 'components/programComonents/pagination/Pagination'
import { CategoriesContext } from 'contextApi/CategoriesContext'
import ReactPaginate from 'react-paginate'
import ArrowLeftIcon from 'ui/ArrowLeftIcon'
import ArrowRightIcon from 'ui/ArrowRightIcon'
const NewsSide = () => {
  const { news, word} = useContext(CategoriesContext);
  const [filteredNews, setFilteredNews] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const ItemsPerPage = 5;
  const itemDisplayed = ItemsPerPage * pageNumber;
  const displayItems = filteredNews
    ?.slice(itemDisplayed, itemDisplayed + ItemsPerPage)
    .map((item) => <NewCard item={item} key={item.id} />);

  const pageCount = Math.ceil(filteredNews?.length / ItemsPerPage);
  
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const filtered = news?.filter(
      (item) =>
        item.title?.toLowerCase().includes(word?.toLowerCase()) ||
        item.description?.toLowerCase().includes(word?.toLowerCase())
    );
    setFilteredNews(filtered || []);
  }, [word, news]);

  useEffect(() => {
    setFilteredNews(news || []);
  }, [news]);
  return (
    <div className='News'>
      <Row>
        {
          displayItems
        }
        <ReactPaginate 
                previousLabel = {<ArrowLeftIcon />}
                nextLabel = {<ArrowRightIcon/>} 
                pageCount={pageCount}
                onPageChange={changePage}

                containerClassName={"Pagination"}
                previousLinkClassName={"btnBaginate"}
                nextLinkClassName={"btnBaginate"}
                disabledClassName={"disable"}
                activeClassName={"btnBaginate btnBaginateActive"}
                />
        {/* <Pagination/> */}
      </Row>
    </div>
  )
}

export default NewsSide