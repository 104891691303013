import React, { useContext } from 'react'
import './Hero.css'
import { Container } from 'react-bootstrap'
// import heroImg from 'assets/images/heroImg.png'
import heroImg from 'assets/images/heroImg.9e684023b10019f816a4.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CategoriesContext } from 'contextApi/CategoriesContext'

const Hero = () => {
    const { lang } = useContext(CategoriesContext);
    const { t } = useTranslation();
  return (
    <div className='heroSection'>
        <Container>
            <div className={`heroContent ${lang === "en" ? 'heroContentEn' : ''}`}>
                <h1>
                    {t('EnhanceYourSkills')}
                    <br/>
                    {t("WithOurSpecialPrograms")}
                </h1>
                <p>
                    {t('TrainingProgramsAndServices')}
                    <br/>
                    {t("ItLeadsYou")}
                </p>
                <Link to='/programs'>
                    {t('ExplorePrograms')}
                </Link>
            </div>
            <div className={`heroLeft ${lang === "en" ? 'heroLeftEn' : ''}`}>
                <div className='heroImage'>
                    <img src={heroImg} alt='heroImage'/>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default Hero