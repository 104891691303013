import React, { useEffect } from 'react'
import './AboutUs.css'
import MainLayout from 'components/mainLayout/MainLayout'
import CommonSection from 'components/common_section/CommonSection'
import ViewMsg from 'components/aboutComponents/viewsMsg/ViewMsg'
import Qema from 'components/aboutComponents/qema/Qema'
import Strategy from 'components/aboutComponents/strategy/Strategy'
import { useTranslation } from 'react-i18next'
import transition from 'transition'
import { Helmet } from 'react-helmet-async'
const AboutUs = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
},[])
const { t } = useTranslation(); 

  return (
    <>
    <Helmet>
    <title>S.E.T.C || ABOUT-US</title>
          <meta
            name="description"
            content="رؤيتنا:خدمة متميزة تتسم بالمهنية و الاحترافية , رسالتنا: ان نكون بيت خبرة لعقد الشراكات مع جميع القطاعات، مع التزامنا بقيمنا و تميزنا بالجودة و تخفيض التكاليف و المحافظة علي البيئة و بما يحقق رؤية االمملكة ٢٠٣٠"
          />
          <link rel='canonical' href='/about'/>
    </Helmet>
      <MainLayout>
          <CommonSection title={t('about')}/>
          <div className='aboutbody'>
              <ViewMsg/>
              <Qema/>
              <Strategy/>
          </div>
      </MainLayout>
    </>
  )
}

export default transition(AboutUs)