import React from 'react'

const ArrowLeftIcon = () => {
  return (
    <div className='btnBaginate'>
        <span><i className="ri-arrow-left-s-line"></i></span>
    </div>
  )
}

export default ArrowLeftIcon